import React from "react";
import {useStaticQuery, graphql} from "gatsby";
import {Helmet} from "react-helmet";

const Seo = ({title, description}) => {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );

  const defaultTitle = site.siteMetadata?.title;
  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{lang: "es"}}
      title={title}
      titleTemplate={title === defaultTitle ? null : title ? `%s - ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        }
      ]}
    />
  );
};

export default Seo;