import React from "react";
import {graphql, useStaticQuery, Link} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {FiMenu, FiX} from "react-icons/fi";
import styled from "styled-components";

const Container = styled.div`
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 60px;
  background: ${props => props.menuOpen ? "#1c658c" : "white"};
  z-index: 3;

  @media (min-width: 768px) {
    padding: 0 30px;
    background: linear-gradient(to top, rgba(26, 26, 26, 0), rgba(26, 26, 26, 0.8));
  }

  @media (min-width: 1440px) {
    padding: 0 40px;
  }

  @media (min-width: 1920px) {
    height: 70px;
  }
`;

const Logo = styled(Link)`
  display: none;
  width: 105px;
  height: 26px;
  cursor: pointer;

  @media (min-width: 768px) {
    display: block;
  }

  @media (min-width: 1024px) {
    width: 121px;
    height: 30px;
  }

  @media (min-width: 1440px) {
    width: 145px;
    height: 36px;
  }

  @media (min-width: 1920px) {
    width: 169px;
    height: 42px;
  }
`;

const LogoBlack = styled(Logo)`
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

const Links = styled.div`
  display: none;
  justify-content: right;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
  }
`;

const LinkCustom = styled(Link)`
  padding: 7px 0;
  margin-right: 30px;
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 0.7rem;
  text-transform: uppercase;
  
  &.active {
    border-bottom: 2px solid #398ab9;
    padding-bottom: 5px !important;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: 1024px) {
    margin-right: 35px;
    font-size: 0.8rem;
    line-height: 0.8rem;
  }

  @media (min-width: 1440px) {
    margin-right: 40px;
    font-size: 0.9rem;
    line-height: 0.9rem;
  }

  @media (min-width: 1920px) {
    margin-right: 50px;
    font-size: 1rem;
    line-height: 1rem;
  }
`;

const MenuButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;
  color: black;
  cursor: pointer;

  & > svg {
    margin-left: 6px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const MenuButtonOpen = styled(MenuButton)`
  color: white;
`;

const Header = ({menuOpen, setMenuOpen}) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
          )
        }
      }
      logoWhite: file(relativePath: { eq: "logo_white.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
          )
        }
      }
    }
  `);

  return (
    <Container menuOpen={menuOpen}>
      <Logo to="/">
        <GatsbyImage image={getImage(data.logoWhite)} alt="Rio Pacifico"/>
      </Logo>
      <LogoBlack to="/">
        {menuOpen ? (
          <GatsbyImage image={getImage(data.logoWhite)} alt="Rio Pacifico"/>
        ) : (
          <GatsbyImage image={getImage(data.logo)} alt="Rio Pacifico"/>
        )}
      </LogoBlack>
      <Links>
        <LinkCustom to="/" activeClassName="active">Inicio</LinkCustom>
        <LinkCustom to="/nosotros" activeClassName="active">Nosotros</LinkCustom>
        <LinkCustom to="/marcas" activeClassName="active">Marcas que confían en nosotros</LinkCustom>
        <LinkCustom to="/contacto" activeClassName="active">Contáctanos</LinkCustom>
      </Links>
      {menuOpen ? (
        <MenuButtonOpen onClick={() => setMenuOpen(false)}>
          Cerrar <FiX size={18}/>
        </MenuButtonOpen>
      ) : (
        <MenuButton onClick={() => setMenuOpen(true)}>
          Menu <FiMenu size={18}/>
        </MenuButton>
      )}
    </Container>
  );
};

export default Header;