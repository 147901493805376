import React from "react";
import {Link} from "gatsby";
import {AiFillHome} from "react-icons/ai";
import {BsFillPeopleFill, BsTelephoneFill} from "react-icons/bs";
import {FaHandshake} from "react-icons/fa";
import {FiChevronRight} from "react-icons/fi";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LinkCustom = styled(Link)`
  display: flex;
  align-items: center;
  border-bottom: 2px solid #1c658c;
  padding: 15px 20px;
  width: 100%;
  background-color: white;
  color: black;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const Icon = styled.div`
  margin-right: ${(props) => props.end === "true" ? "0" : "10px"};
  min-width: 24px;
  height: 24px;
`;

const Name = styled.div`
  flex-grow: 1;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 0.8rem;
`;

const Menu = ({setMenuOpen}) => {
  const handleClick = () => {
    setMenuOpen(false);
  };

  return (
    <Container>
      <Links>
        <LinkCustom to="/" onClick={handleClick}>
          <Icon><AiFillHome size={20} color="#2c79b7"/></Icon>
          <Name>Inicio</Name>
          <Icon end="true"><FiChevronRight size={20}/></Icon>
        </LinkCustom>
        <LinkCustom to="/nosotros" onClick={handleClick}>
          <Icon><BsFillPeopleFill size={20} color="#2c79b7"/></Icon>
          <Name>Nosotros</Name>
          <Icon end="true"><FiChevronRight size={20}/></Icon>
        </LinkCustom>
        <LinkCustom to="/marcas" onClick={handleClick}>
          <Icon><FaHandshake size={20} color="#2c79b7"/></Icon>
          <Name>Marcas que confian en nosotros</Name>
          <Icon end="true"><FiChevronRight size={20}/></Icon>
        </LinkCustom>
        <LinkCustom to="/contacto" onClick={handleClick}>
          <Icon><BsTelephoneFill size={20} color="#2c79b7"/></Icon>
          <Name>Contacto</Name>
          <Icon end="true"><FiChevronRight size={20}/></Icon>
        </LinkCustom>
      </Links>
    </Container>
  );
};

export default Menu;