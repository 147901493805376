import React from "react";
// import {BsFillTelephoneFill} from "react-icons/bs";
import {MdEmail} from "react-icons/md";
import {FaLinkedinIn} from "react-icons/fa";
import styled from "styled-components";

const Container = styled.div`
  grid-area: footer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(26, 26, 26, 0), rgba(26, 26, 26, 0.4));
  z-index: 1;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    padding: 0;
    height: 60px;
  }

  @media (min-width: 1920px) {
    height: 65px;
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  text-decoration: none;
  text-shadow: rgb(0 0 0 / 50%) 0 0 10px;
  color: white;

  &:last-child {
    margin-bottom: 0;
  }

  & > svg {
    margin-right: 6px;
  }

  @media (min-width: 768px) {
    padding: 5px 15px;
  }

  @media (min-width: 1440px) {
    padding: 5px 20px;
    font-size: 1.1rem;
    line-height: 1.1rem;
  }
`;

const Footer = () => {
  return (
    <Container>
      {/*<Link href="tel:+5117141460" target="_blank">*/}
      {/*  <BsFillTelephoneFill size={18}/> (+51 1) 7141460*/}
      {/*</Link>*/}
      <Link href="mailto:proyectos@riopacifico.net" target="_blank">
        <MdEmail size={18}/> proyectos@riopacifico.net
      </Link>
      <Link href="https://pe.linkedin.com/company/r%C3%ADo-pac%C3%ADfico-sac" target="_blank">
        <FaLinkedinIn size={18}/> company/rio-pacifico-sac
      </Link>
    </Container>
  );
};

export default Footer;